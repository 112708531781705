.table-container {
    height: calc(100vh - 164px);
    overflow: auto;
    
    ::ng-deep {
      .p-datatable {
        .p-datatable-wrapper {
          overflow: auto;
          
          // Set minimum width to force horizontal scroll
          table {
            min-width: 1200px; // Adjust based on your needs
          }
          
          // Sticky header
          .p-datatable-thead > tr > th {
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: var(--surface-section);
          }
        }
  
        // Custom scrollbar styling
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
  
        &::-webkit-scrollbar-thumb {
          background: #c1c1c1;
          border-radius: 4px;
        }
  
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 4px;
        }
      }
    }
  }