@use "sass:math";

// Valor Base
$spacing-base: 8px;

// Escala de Espaciado
$spacing-xs: $spacing-base; // 8px
$spacing-sm: $spacing-base * 2; // 16px
$spacing-md: $spacing-base * 3; // 24px
$spacing-lg: $spacing-base * 4; // 32px
$spacing-xl: $spacing-base * 5; // 40px
$spacing-xxl: $spacing-base * 6; // 48px

// Breakpoints para Responsividad
$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

// Margenes y Paddings Proporcionales
@mixin proportional-spacing($property, $size) {
    #{$property}: $size;
    
    @media (max-width: $breakpoint-xs) {
        #{$property}: math.div($size, 2);
    }
    
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        #{$property}: $size * 0.75;
    }
    
    @media (min-width: $breakpoint-lg) {
        #{$property}: $size;
    }
    
    @media (min-width: $breakpoint-xxl) {
        #{$property}: $size * 1.25;
    }
}