@import 'typography';
@import 'mixins';
@import 'shared-table';

body {
    margin: 0;
    background-color: white;
    height: 100vh;
}

p.sub {
    color: hsla(0, 0%, 10%, .7);
    font-weight: 400;
    font-size: 14px;
}

.actions-row {
    @include proportional-spacing(padding, $spacing-xs);
    padding: $spacing-xs;
    background-color: white;
}

.p-sidebar-right.custom-sidebar {
    width: 600px;
    max-width: 600px;
}

div.table-container {
    height: calc(100vh - 200px);
    overflow: auto;
}

.scrollable-page {
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 2rem;
}